@font-face {
  font-family: 'NoirPro';
  src: url('NoirPro-Light.eot?#iefix') format('embedded-opentype'),
  url('NoirPro-Light.woff') format('woff'),
  url('NoirPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url('NoirPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('NoirPro-Regular.woff') format('woff'),
  url('NoirPro-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url('NoirPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('NoirPro-Medium.woff') format('woff'),
  url('NoirPro-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url('NoirPro-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('NoirPro-SemiBold.woff') format('woff'),
  url('NoirPro-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url('NoirPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('NoirPro-Bold.woff') format('woff'),
  url('NoirPro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url('NoirPro-Heavy.eot?#iefix') format('embedded-opentype'),
  url('NoirPro-Heavy.woff') format('woff'),
  url('NoirPro-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Segoe UI';
  src: local("Segoe UI"),
       local("Segoe"),
       local("Segoe WP"),
       url('../segoe-ui/SegoeUI.eot') format('embedded-opentype'),
       url('../segoe-ui/SegoeUI.woff') format('woff');
  font-weight: 300;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Segoe UI';
  src: url('../segoe-ui/segoe-ui-bold.eot') format('embedded-opentype'),
  url('../segoe-ui/segoe-ui-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}